import { Link } from 'gatsby'
import React, { Fragment } from 'react'
import '../fonts/fonts.css'
import logo from '../images/Source_File_Main_logo 1.png'
import { Menu, Transition } from '@headlessui/react'
import '../styles/stylesheet.css'
// import Sidebar from './sidebar'
// import location from '../images/bx_bx-current-location.png'
import '../styles/Animation.css'



const Header = () => {
  // const [active, setActive] = useState(false)

  // const openSidebar = () => {
  //   setActive(true)
  // }

  // const closeSidebar = () => {
  //   setActive(false)
  // }

  const navItem = [
    
    { name: 'MENU', url: '/menu' },
    { name: 'CATERING', url: '/catering' },
    { name: 'FRANCHISE', url: '/franchise' },
    { name: 'ABOUT', url: '/about' },
  ]
  return (

    <header className="sticky top-0 z-20 ">

      <Menu as="div" className="overflow-x-hidden bg-white shadow-lg ">
        {({ open }) => (
          <Fragment>
            <div className="w-screen flex p-4 md:lg-4 md:px-8 lg:py-6 lg:px-12">
              <div className="flex items-center justify-between w-full">

                <Menu.Button className="inline-flex lg:hidden items-center justify-center p-2 rounded-md  hover:text-white focus:outline-none focus:bg-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6 " aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6 text-navyBlue hover:text-navyBlue" aria-hidden="true" />

                  )}
                </Menu.Button>
                <Link to="/">
                  <img src={logo} alt="spice brew logo" className="lg:ml-1 xl:ml-12 lg:w-28 md:w-28 sm:w-24 w-20 " />

                </Link>

                {/*large display menu items */}
                <div className="hidden lg:flex items-center">
                  {navItem.map((item) => {
                    return (
                      <Link to={item.url} className="xl:px-5 lg:px-4 capitalize Moderat-Extended-Regular text-sm" activeStyle={{ color: '#074e81' }}>{item.name}</Link>
                    )
                  })}
                  <Link to="http://spice-brew.square.site" className="flex flex-col items-center">

                    <button className="fancy">
                      <span className="top-key"></span>
                      <div className="flex items-center">
                        <a  className="capitalize">order now</a>
                        
                      </div>
                      <span className="bottom-key-1"></span>
                      <span className="bottom-key-2"></span>
                    </button>
                  </Link>

                </div>

                <Link to="http://spice-brew.square.site" className="flex flex-col items-center lg:hidden">

                  <button className="fancy">
                    <span className="top-key"></span>
                    <div className="flex items-center">
                      <a href="http://spice-brew.square.site" className="capitalize">order now</a>
                    </div>
                    <span className="bottom-key-1"></span>
                    <span className="bottom-key-2"></span>
                  </button>
                </Link>
                {/* <Link to="/tray">
                    <img src={cartIcon} alt="" className="flex xl:ml-12 lg:ml-6 lg:w-10 xl:w-14" />
                  </Link>
               

                 small device menu 
                {/* <Link to="" className="lg:hidden">
                  <img src={cartIcon} alt="" className="flex w-8 lg:w-auto" /> 
                </Link> */}




              </div>
            </div>



            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="lg:hidden absolute w-full grid p-2 md:lg-4 md:px-8 lg:py-6 lg:px-12 bg-white z-20 shadow">
                {navItem.map((item, index) => {
                  if (index >= 0) {
                    return (
                      <Menu.Item>
                        <Link to={item.url} className="px-5 py-2 capitalize  Moderat-Extended-Regular text-xs" activeStyle={{ color: '#074e81' }}>{item.name}</Link>

                      </Menu.Item>
                    )
                  }return null
                })}
              </Menu.Items>
            </Transition>
          </Fragment>
        )}
      </Menu>

      

    </header >
  )
}

export default Header


function MenuIcon(props) {
  return (

    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black hover:text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
    </svg>
  )
};

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black hover:text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  )
}


