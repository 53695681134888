import React from 'react'
import Header from '../components/header'


import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Layout = (probs) => {
  const data = useStaticQuery(graphql`
  query{
      backgroundImg: file(relativePath:{eq:"Pattern-Concepts-03-1.jpg"}){
          childImageSharp{
              fluid(maxWidth:4000){
                  ...GatsbyImageSharpFluid
              }
          }
      }
  }
  `
  )

  return (
    <main>

      <BackgroundImage
        fluid={data.backgroundImg.childImageSharp.fluid}
        style={{minHeight:'100vh',minWidth:'100%',backgroundRepeat:'repeat',backgroundSize:'contain'}}
        className=""
      >
        <Header />
        {probs.children}
        
      </BackgroundImage>
      
      


    </main>
  )
}

export default Layout